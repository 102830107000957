<template>
      <a-form :form="form" @submit.prevent="handleSubmit">
        <h5 class="text-black mt-2 mb-3">
          <strong>Password Lama</strong>
        </h5>
        <div class="row">
          <div class="col-lg-6">
            <a-form-item label="Password">
              <a-input-password :disabled="submitting" placeholder="Password" v-decorator="['oldPassword', {rules: [{ required: true }]}]" />
            </a-form-item>
          </div>
        </div>
        <h5 class="text-black mt-2 mb-3">
          <strong>Password Baru</strong>
        </h5>
        <div class="row">
          <div class="col-lg-6">
            <a-form-item label="Password">
              <a-input-password :disabled="submitting" placeholder="Password" v-decorator="['newPassword', {rules: [{ required: true }]}]" />
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item label="Confirm Password">
              <a-input-password :disabled="submitting" placeholder="Confirm Password" v-decorator="['confirmPassword', {rules: [{ required: true }]}]" />
            </a-form-item>
          </div>
        </div>
        <div class="form-actions mt-0">
          <a-button style="width: 150px;" type="primary" htmlType="submit" class="mr-3">Submit</a-button>
          <!-- <a-button htmlType="submit">Cancel</a-button> -->
        </div>
      </a-form>
</template>
<script>
import { changePassword } from '@/services/axios/api/auth'
export default {
  data () {
    return {
      form: this.$form.createForm(this),
      submitting: false,
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          if (values.newPassword === values.confirmPassword) {
            const { oldPassword, newPassword } = values
            try {
              let res = {}
              if (this.$store.state.user.role !== 'YKI') res = await changePassword(this.$store.state.user.id, { oldPassword, newPassword, role: this.$store.state.user.role })
              else res = await changePassword(this.$store.state.user.id, { oldPassword, newPassword, role: 'verificator' })
              if (res && res.data) {
                this.$notification.success({
                  message: 'Sukses',
                  description: 'Data password berhasil diubah',
                  duration: 5,
                })
              }
              this.submitting = false
            } catch (e) {
              this.submitting = false
            }
          } else {
            this.$notification.warning({
              message: 'Gagal',
              description: 'Konfirmasi password tidak sesuai dengan password baru',
              duration: 5,
            })
          }
        }
      })
    },
  },
}
</script>

<style>

</style>
